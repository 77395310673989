.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: Arial;
  color: white;
  backdrop-filter: blur(5px);
}

.modal-main {
  position: fixed;
  background: #0f141e;
  border-radius: 20px;
  padding: 10px;
  width: 80%;
  height: auto;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

label {
  font-size: 14px;
}
