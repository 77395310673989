.toggle-switch {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background-color: #191e2e;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 20px;
  transform: scale(0.8);
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.toggle-switch:checked::before {
  background-color: #fff;
  left: 24px;
}

.toggle-switch:checked {
  background-color: #267ee5;
}
